import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { Button } from "react-md";

export default function Hero() {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "images/Contact Page/Rectangle 7.png" }
        ) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    // <div className="contact-banner page-hero">
    <BackgroundImage
      Tag="section"
      className="contact-banner page-hero"
      fluid={imageData}
      backgroundColor={`#303d4e`}
    >
      <div className="container">
        <h2>Interested in creating an account or getting a quote?</h2>
        <div className="index__hero-buttons">
          <Link to="/teachers-schools">
            <Button
              className="index__hero-button"
              theme="secondary"
              themeType="contained"
            >
              Teachers&#x200a;/&#x200a;Schools
            </Button>
          </Link>
          <Link to="/students">
            <Button
              className="index__hero-button"
              theme="secondary"
              themeType="contained"
            >
              Students
            </Button>
          </Link>
        </div>
      </div>
    </BackgroundImage>
    // </div>
  );
}
