import React from "react";
import Layout from "../components/Layout/Layout";
import Hero from "../components/Contact/Hero.js";
import ContactForm from "../components/Contact/ContactForm.js";

import "../styles/pages/contact.scss";

export default function Contact({ location }) {
  const params = new URLSearchParams(location.search);
  const embedded = params.get("e");
  const isEmbedded = embedded === "1";
  return (
    <Layout isEmbedded={isEmbedded}>
      {!isEmbedded && <Hero />}
      <ContactForm />
    </Layout>
  );
}
