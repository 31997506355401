import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Select, TextField, TextArea, Button } from "react-md";
import { RiArrowDownSFill } from "@react-icons/all-files/ri/RiArrowDownSFill";
import { getDeltaMathAPI } from "../../utilities/utilities";

function ContactForm() {
  const STRING_ITEMS = [
    "Teacher",
    "Student",
    "Parent",
    "School Administrator",
    "Other",
  ];
  const questions = ["Ordering/Purchasing", "Tech Support", "Other"]; // "Bug Report",
  const [userType, setUserType] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);
  const [submittingNow, setSubmittingNow] = React.useState(false);

  const {
    control,
    register,
    handleSubmit,
    errors,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm();


  // HANDLING FORM SUBMISSION
  const onSubmit = async (data) => {
    if (submittingNow) return;

    try {
      let body = {
        personType: data.queryingUserType,
        name: data.nameTextField,
        email: data.emailTextField,
        messageType: data.usersQuestion,
        message: data.messageTextArea,
      };
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      };
      setSubmittingNow(true);

      fetch(getDeltaMathAPI() + "/contact", requestOptions)
        .then((response) => response.json())
        .then((data) => {
          setSubmittingNow(false);
          setSubmitted(true);
          reset();
        });
    } catch (error) {
      // handle server errors
      setSubmittingNow(false);
      setError("submit", {
        type: "manual",
        message: "Your message couldn't be sent.",
      });
    }
  };

  const showSubmitError = (msg) => {
    return (
      <div className="validation-alert msg-error">
        <p>{msg.message}</p>
      </div>
    );
  };

  const showThankYou = (
    <div className="validation-alert msg-confirm">
      <p>Thank you. We will get back to you shortly.</p>
    </div>
  );

  return (
    <div className="contact-form-section">
      <div className="container light-bg">
        <h2>Contact Us</h2>
        <p className="content">
          Questions? Fill out the form below, and we'll get back to you within
          1-2 business days.
        </p>
        <form className="contact-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="column">
              <p className="label">I'm a...</p>
              <Controller
                control={control}
                name="queryingUserType"
                defaultValue=""
                rules={{
                  required: "You must select from the first dropwn menu.",
                }}
                disabled={isSubmitting}
                render={(props) => (
                  <Select
                    id="custom-select-1"
                    name="customSelect1"
                    label="--Select--"
                    placeholder="--Select--"
                    {...props}
                    options={STRING_ITEMS}
                    value={userType}
                    onChange={(value) => {
                      props.onChange(value);
                      setUserType(value);
                    }}
                    disableLeftAddon={false}
                    rightChildren={
                      <RiArrowDownSFill className="dropDownArrow" />
                    }
                  />
                )}
              />
            </div>
            <div className="column">
              <p className="label">I have a question about...</p>
              <Controller
                control={control}
                name="usersQuestion"
                defaultValue=""
                rules={{
                  required: "Please select your question topic.",
                }}
                disabled={isSubmitting}
                render={(props) => (
                  <Select
                    id="custom-select-2"
                    label="--Select--"
                    placeholder="--Select--"
                    {...props}
                    options={questions}
                    value={question}
                    onChange={(value) => {
                      props.onChange(value);
                      setQuestion(value);
                    }}
                    rightChildren={
                      <RiArrowDownSFill className="dropDownArrow" />
                    }
                  />
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <TextField
                id="name-text-field"
                name="nameTextField"
                label="Name"
                ref={register({ required: "Name is required." })}
                disabled={isSubmitting}
              />
              <TextField
                id="email-text-field"
                name="emailTextField"
                type="email"
                label="Email Address"
                ref={register({
                  required: "Please enter a valid email address.",
                })}
                disabled={isSubmitting}
              />
            </div>
            <div className="column">
              <TextArea
                id="message-textarea"
                name="messageTextArea"
                key="message-textarea-key"
                rows={5}
                resize={"none"}
                animate={true}
                label="Message..."
                ref={register({ required: "Please enter your message." })}
                disabled={isSubmitting}
              />
            </div>
          </div>
          <div className="row error-row">
            {errors.queryingUserType && (
              <div className="validation-alert msg-error ">
                <p>{errors.queryingUserType.message}</p>
              </div>
            )}
            {errors.usersQuestion && (
              <div className="validation-alert msg-error ">
                <p>{errors.usersQuestion.message}</p>
              </div>
            )}
            {errors.nameTextField && (
              <div className="validation-alert msg-error ">
                <p>{errors.nameTextField.message}</p>
              </div>
            )}
            {errors.emailTextField && (
              <div className="validation-alert msg-error ">
                <p>{errors.emailTextField.message}</p>
              </div>
            )}
            {errors.messageTextArea && (
              <div className="validation-alert msg-error ">
                <p>{errors.messageTextArea.message}</p>
              </div>
            )}
            {errors && errors.submit && showSubmitError(errors.submit)}
            {/* {errors && errors.submit && showForgetPassword} */}
            {submitted && showThankYou}
          </div>
          <div className="row">
            <Button
              className="form-submit-btn"
              type="submit"
              themeType="contained"
            >
              Send Message
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
